var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as Action from "../Types/ReducerTypes";

var reducer = function (state, action) {
  switch (action.type) {
    case Action.COUNTRY_CHANGE_COUNTRY:
      return __assign(__assign({}, state), {
        country: action.country
      });

    case Action.COUNTRY_CHANGE_DEVICE:
      return __assign(__assign({}, state), {
        device: action.device
      });

    default:
      return state;
  }
};

export default reducer;