import urlBase64ToUint8Array from "./urlBase64";
import axios from "axios";

var Webpush =
/** @class */
function () {
  function Webpush(w, ws, callbackUrl, publicKey) {
    this.w = w;
    this.ws = ws;
    this.callbackUrl = callbackUrl;
    this.publicKey = publicKey;
    this.domain = window.location.hostname;
    this.valid = "serviceWorker" in navigator;
    this.workerUrl = "/service-worker.js";
    this.hostId = 0;

    if (!("PushManager" in window)) {
      this.valid = false;
    }

    this.askPermission = this.askPermission.bind(this);
  }
  /**
   *
   */


  Webpush.prototype.isValid = function () {
    return this.valid;
  };

  Webpush.prototype.deactivate = function (hostId) {
    var _this = this;

    return this.registerServiceWorker().then(function (registration) {
      return registration.pushManager.getSubscription();
    }).then(function (subscription) {
      return axios.delete(_this.callbackUrl + '/host', {
        params: {
          subscription: subscription,
          host: hostId
        }
      });
    }).then(function (response) {
      return response.data;
    }).catch(function () {// do nothing
    });
  };

  Webpush.prototype.activate = function (hostId) {
    var _this = this;

    if (!this.valid) {
      // Does not know serviceworker or PushManager, so the whole thing is off...
      return;
    }

    this.hostId = hostId;
    return Promise.all([this.registerServiceWorker(), this.getNotificationPermissionState()]).then(function (results) {
      var registration = results[0];
      var currentPermissionState = results[1];

      if (currentPermissionState === "denied") {
        throw new Error('Sie haben die Benachrichtigung durch uns verweigert, weswegen wir Ihnen leider keine Webpushes schicken können.');
      } // Do we need to wait for permission?


      var promiseChain;

      if (currentPermissionState !== "granted") {
        promiseChain = _this.askPermission();
      } else {
        promiseChain = registration.pushManager.getSubscription();
      }

      return promiseChain.then(_this.subscribeUserToPush.bind(_this)).then(function (subscription) {
        if (subscription) {
          return _this.sendSubscriptionToBackEnd({
            domain: _this.domain,
            host: _this.hostId,
            subscription: subscription,
            w: _this.w,
            ws: _this.ws
          }).then(function () {
            return subscription;
          });
        }

        return subscription;
      }).catch(function (err) {
        throw new Error("Webpush Registrierung konnte nicht vorgenommen werden: " + err.message);
      });
    }).catch(function (err) {
      throw new Error("Service Worker konnte nicht registriert werden: " + err.message);
    });
  };
  /**
   * Register the service worker with the Browser
   */


  Webpush.prototype.registerServiceWorker = function () {
    return navigator.serviceWorker.register(this.workerUrl).then(function (registration) {
      return registration;
    }).catch(function (err) {
      throw new Error("Service Worker konnte nicht registriert werden: " + err.message);
    });
  };
  /**
   * Ask for Permission to do push messages
   *
   * @returns {PromiseLike}
   */


  Webpush.prototype.askPermission = function () {
    return new Promise(function (resolve, reject) {
      var permissionResult = Notification.requestPermission(function (result) {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then(function (permissionResult) {
      if (permissionResult !== "granted") {
        throw new Error("Erlaubnis wurde verweigert");
      }

      return permissionResult;
    });
  };
  /**
   * Get the state of permissions
   */


  Webpush.prototype.getNotificationPermissionState = function () {
    if (navigator && navigator.permissions && navigator.permissions.query) {
      var query = navigator.permissions.query({
        name: "notifications"
      });

      if (typeof query.then === "function") {
        return query.then(function (result) {
          return result.state;
        });
      }
    }

    return new Promise(function (resolve) {
      resolve(Notification.permission);
    });
  };
  /**
   * Subscribe User to ouz Push Notification
   *
   * @returns PushSubscription
   */


  Webpush.prototype.subscribeUserToPush = function () {
    var subscribeOptions = {
      applicationServerKey: urlBase64ToUint8Array(this.publicKey),
      userVisibleOnly: true
    };
    return this.registerServiceWorker().then(function (registration) {
      if (registration) {
        return registration.pushManager.subscribe(subscribeOptions);
      }
    }).then(function (pushSubscription) {
      return pushSubscription;
    });
  };
  /**
   * Saves subscription information in the backend
   *
   * @param data
   */


  Webpush.prototype.sendSubscriptionToBackEnd = function (data) {
    var _this = this;

    return new Promise(function (resolve, reject) {
      var request = new XMLHttpRequest();
      request.open("POST", _this.callbackUrl, true);
      request.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          resolve(request.responseText);
        } else {
          reject(request.responseText);
        }
      };

      request.onerror = function () {
        reject("Failed to connect");
      };

      request.send(JSON.stringify(data));
    });
  };

  Webpush.prototype.getRegisteredHostsId = function () {
    var _this = this;

    return this.registerServiceWorker().then(function (registration) {
      return registration.pushManager.getSubscription();
    }).then(function (subscription) {
      if (subscription !== null) {
        return axios.get(_this.callbackUrl + '/hosts', {
          params: {
            subscription: subscription
          }
        });
      }

      return Promise.reject();
    }).then(function (response) {
      return response.data;
    });
  };

  return Webpush;
}();

export default Webpush;