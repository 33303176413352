var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

export var GoogleEvent;

(function (GoogleEvent) {
  GoogleEvent["HOST_MODALBOX"] = "host.modalbox";
  GoogleEvent["HOST_NUMBER"] = "host.number";
  GoogleEvent["HOST_WEBPUSH"] = "host.webpush";
  GoogleEvent["HEADER_COUNTRY_SELECTION"] = "header.countrySelection";
  GoogleEvent["HEADER_DEVICE_SELECTION"] = "header.deviceSelection";
  GoogleEvent["HEADER_START_CHAT"] = "header.startChat";
  GoogleEvent["HEADER_WRONG_CODE"] = "header.wrongCode";
  GoogleEvent["STICKY_START_CHAT"] = "sticky.startChat";
  GoogleEvent["STICKY_WRONG_CODE"] = "sticky.wrongCode";
  GoogleEvent["CHAT_START"] = "header.openChat";
  GoogleEvent["CHAT_END"] = "chat.end";
  GoogleEvent["PROFILE_BUTTON_TO_VX"] = "profile.buttonToVX";
  GoogleEvent["PROFILE_NUMBER"] = "profile.number";
  GoogleEvent["PROFILE_LIGHTBOX"] = "profile.lightbox";
  GoogleEvent["PROFILE_WEBPUSH"] = "profile.webpush";
  GoogleEvent["MODALBOX_BUTTON_TO_VX"] = "modalbox.buttonToVX";
  GoogleEvent["MODALBOX_LINK_TO_VX"] = "modalbox.linkToVX";
  GoogleEvent["MODALBOX_WEBPUSH"] = "modalbox.webpush";
  GoogleEvent["MODALBOX_GALLERY"] = "modalbox.gallery";
  GoogleEvent["MODALBOX_NUMBER"] = "modalbox.number";
  GoogleEvent["MODALBOX_SWITCH_INFO"] = "modalbox.switchInfo";
  GoogleEvent["MODALBOX_START_CHAT"] = "modalbox.startChat";
  GoogleEvent["MODALBOX_WRONG_CODE"] = "modalbox.wrongCode";
  GoogleEvent["FOOTER_PAGINATION"] = "footer.pagination";
  GoogleEvent["OVERLAY_ENTER"] = "overlay.enter";
  GoogleEvent["OVERLAY_EXIT"] = "overlay.exit";
  GoogleEvent["NAVIGATION_HOME"] = "navigation.home";
  GoogleEvent["NAVIGATION_ANAL"] = "navigation.anal";
  GoogleEvent["NAVIGATION_MILF"] = "navigation.milf";
  GoogleEvent["NAVIGATION_FETISH"] = "navigation.fetish";
  GoogleEvent["NAVIGATION_PHONESEX"] = "navigation.phonesex";
  GoogleEvent["SURVEY_CANCELED"] = "survey.canceled";
  GoogleEvent["SURVEY_SUBMITTED"] = "survey.submitted";
  GoogleEvent["SURVEY_SOFT_SUBMITTED"] = "survey.softsubmit";
})(GoogleEvent || (GoogleEvent = {}));

export function sendGoogleTransaction(transAction) {
  try {
    if (typeof dataLayer === "undefined") {
      dataLayer = [];
    }

    dataLayer.push(__assign({}, transAction));
  } catch (e) {// Do nothing
  }
}
export function sendGtmEvent(e, label, data) {
  try {
    if (dataLayer === undefined) {
      dataLayer = [];
    }

    var parts = e.split(".");
    var category = parts[0];
    parts.shift();
    var event_1 = {
      event: e,
      category: category,
      action: parts.join("."),
      label: label,
      data: data
    };
    dataLayer.push(__assign({}, event_1));
  } catch (e) {//
  }
}