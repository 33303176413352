export var REPLACE_HOST_LIST = "replaceHostList";
export var EXTEND_HOST_LIST = "extendHostList";
export var HOST_NEW_SELECTED = "newHostSelected";
export var HOST_REMOVE_SELECTED = "removeHostSelected";
export var HOST_CHANGED_STATUS = "changeStatusHost";
export var GO_TO_PAGE = "goToPage";
export var SET_REGISTERED_HOSTS = "setRegistered";
export var ADD_REGISTERED_HOST = "addRegistered";
export var REMOVE_REGISTERED_HOST = "removeRegistered";
export var COUNTRY_CHANGE_COUNTRY = "changeCountry";
export var COUNTRY_CHANGE_DEVICE = "changeDevice";
export var FEEDBACK_ADD_MESSAGE = "addMessage";
export var FEEDBACK_REMOVE_MESSAGE = "removeMessage";
export var START_CHAT = "startChat";
export var END_CHAT = "endChat";
export var SURVEY_END = "surveyEnd";