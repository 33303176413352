var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

import * as Action from "../Types/ReducerTypes";
import { ChatStates } from "../Interfaces/Stores/IHostStore";
import sortHosts from "../Helper/HostListSorter";

function updateStatus(hosts, action) {
  var newHosts = __spreadArray([], hosts, true);

  return newHosts.map(function (host) {
    if (host.id === parseInt(action.userId, 10)) {
      return __assign(__assign({}, host), {
        status: action.status,
        ddi: typeof action.ddi === 'undefined' || action.ddi === '' ? host.ddi : action.ddi
      });
    }

    return host;
  });
}

function extendHostList(oldHosts, newHosts) {
  var knownIds = oldHosts.map(function (host) {
    return host.id;
  });
  var diff = newHosts.filter(function (host) {
    return knownIds.indexOf(host.id) === -1;
  });
  return __spreadArray(__spreadArray([], oldHosts, true), diff, true);
}

function isChatAction(action) {
  var type = action.type;
  return type === Action.START_CHAT || type === Action.END_CHAT || type === Action.SURVEY_END;
}

var reducer = function (state, action) {
  if (!isChatAction(action)) {
    switch (action.type) {
      case Action.HOST_CHANGED_STATUS:
        var theSelected = state.selected;

        if (theSelected !== null && theSelected.id === parseInt(action.userId, 10)) {
          theSelected = __assign(__assign({}, state.selected), {
            status: action.status,
            ddi: typeof action.ddi === 'undefined' || action.ddi === '' ? theSelected.ddi : action.ddi
          });
        }

        return __assign(__assign({}, state), {
          selected: theSelected,
          hosts: sortHosts(updateStatus(state.hosts, action))
        });

      case Action.REPLACE_HOST_LIST:
        var replaceHosts = sortHosts(action.hosts);
        return __assign(__assign({}, state), {
          hosts: replaceHosts
        });

      case Action.EXTEND_HOST_LIST:
        var newHosts = sortHosts(extendHostList(state.hosts, action.hosts));
        return __assign(__assign({}, state), {
          hosts: newHosts
        });

      case Action.SET_REGISTERED_HOSTS:
        return __assign(__assign({}, state), {
          registered: action.registered
        });

      case Action.ADD_REGISTERED_HOST:
        return __assign(__assign({}, state), {
          registered: __spreadArray(__spreadArray([], state.registered, true), [parseInt(action.userId, 10)], false)
        });

      case Action.REMOVE_REGISTERED_HOST:
        return __assign(__assign({}, state), {
          registered: state.registered.filter(function (id) {
            return id !== parseInt(action.userId, 10);
          })
        });

      case Action.HOST_NEW_SELECTED:
        return __assign(__assign({}, state), {
          selected: action.host
        });

      case Action.HOST_REMOVE_SELECTED:
        return __assign(__assign({}, state), {
          selected: null
        });

      case Action.GO_TO_PAGE:
        return __assign(__assign({}, state), {
          page: action.page
        });

      default: // Do nothing, just return original state

    }
  } else {
    switch (action.type) {
      case Action.START_CHAT:
        return __assign(__assign({}, state), {
          chat: {
            started: Date.now(),
            userId: action.userId,
            screenName: action.screenName,
            chatId: action.chatId,
            clientId: action.clientId,
            server: action.server,
            client: action.client,
            running: action.running
          }
        });

      case Action.END_CHAT:
        return __assign(__assign({}, state), {
          chat: __assign(__assign({}, state.chat), {
            running: action.running
          })
        });

      case Action.SURVEY_END:
        return __assign(__assign({}, state), {
          chat: __assign(__assign({}, state.chat), {
            started: 0,
            userId: '',
            screenName: '',
            chatId: '',
            clientId: '',
            server: '',
            running: ChatStates.NO
          })
        });

      default: // Do nothing, just return original state

    }
  }

  return state;
};

export default reducer;