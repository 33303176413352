var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

import * as Action from "../Types/ReducerTypes";

var reducer = function (state, action) {
  switch (action.type) {
    case Action.FEEDBACK_ADD_MESSAGE:
      return {
        messages: __spreadArray(__spreadArray([], state.messages, true), [action.message], false)
      };

    case Action.FEEDBACK_REMOVE_MESSAGE:
      return {
        messages: __spreadArray([], state.messages.filter(function (message) {
          return message !== action.message;
        }), true)
      };

    default:
      return state;
  }
};

export default reducer;