export var Callcenter;

(function (Callcenter) {
  Callcenter["first"] = "first";
  Callcenter["tele"] = "tele";
})(Callcenter || (Callcenter = {}));

export var PhonesexNumbers = {
  luder: {
    number: {
      first: '09005 001 196',
      tele: '09005 001 092'
    },
    title: 'Scharfe Luder',
    advertisement: 'Geile Luder stöhnen für dich am Telefon!',
    imageUrl: '/img/telefonsex/overview/AGE/luder.jpg',
    description: 'Erlebe gemeinsam mit diesen Frauen heißen Telefonsex. Wenn Du auf eine unanständige und schnelle Liebelei stehst, sind diese Girls genau das Richtige für dich! Die versauten Flittchen lieben es von dir verführt zu werden.'
  },
  oma: {
    number: {
      first: '09005 001 198',
      tele: '09005 001 094'
    },
    title: 'Reife Omas',
    advertisement: 'Reife Omas erzählen dir ihre geheimen Sexwünsche',
    imageUrl: '/img/telefonsex/overview/AGE/oma.jpg',
    description: 'Heiße Rentnerinnen und ältere Damen. Eine große Auswahl an erfahrenen Grannys sucht erotische Bekanntschaften! Sie möchten Dich nur zu gerne über Telsex verhätscheln. Begib Dich hier in Omas Hände und lass Dich jetzt von liebevollen Frauen über Telefon Sex verwöhnen.'
  },
  hausfrau: {
    number: {
      first: '09005 001 193',
      tele: '09005 001 107'
    },
    title: 'Einsame Hausfrauen',
    advertisement: 'Einsame Haus&shy;frauen möchten dir ihre Vor&shy;lieben gestehen',
    imageUrl: '/img/telefonsex/overview/AGE/hausfrau.jpg',
    description: 'Träumst Du von einer sexy Hausfraue? Dann bist du hier an der richtigen Stelle! Heiße Single-Frauen, Mütter und Hausmädchen, die ihren Alltag durch geilen Telefonsex auffrischen wollen. Diese Damen warten begierig auf Deinen Wedel!'
  },
  anal: {
    number: {
      first: '09005 103 890',
      tele: '09005 001 091'
    },
    title: 'Rattige Anal-Huren',
    advertisement: 'Hör zu wie sie es hart in ihr enges Poloch kriegt!',
    imageUrl: '/img/telefonsex/overview/AGE/anal.jpg',
    description: 'Du stehst auf geile Hintern? Vor allem das enge Hintertürchen hat es dir angetan? Dann ruf jetzt hier an! Diese Anal Schlampen warten nur auf ein geiles Sex-am-Telefon-Abenteuer mit dir. Rimming, Fisting oder Analsex - diesen Frauen machen alles mit!'
  },
  milf: {
    number: {
      first: '09005 001 197',
      tele: '09005 001 093'
    },
    title: 'Willfährige MILFs',
    advertisement: 'Lausche ver&shy;nach&shy;lässigten MILFs am Telefon',
    imageUrl: '/img/telefonsex/overview/AGE/milfs.jpg',
    description: 'Geile Mütter, die von ihren Männern vernachlässigt werden und darum einem ziemlich versautem Hobby nachgehen! Sie alle wollen geilen Telefon Sex mit dir. Wenn Du auf erfahrene und reife Frauen mitten aus dem Leben stehst, bist Du hier genau richtig. Lass Dich jetzt beim Telesex von Mutti gnadenlos verwöhnen!'
  },
  domina: {
    number: {
      first: '09005 104 605',
      tele: '09005 001 089'
    },
    title: 'Extreme Dominas',
    advertisement: 'Scharfe Dominas zeigen dir wo es lang geht',
    imageUrl: '/img/telefonsex/overview/AGE/domina.jpg',
    description: 'Hier wirst Du erniedrigt und bestraft! Diese Frauen kennen keine Gnade. Unterwirf Dich Deiner Herrin und werde zum ihrem devoten Stiefellecker. Beweise den Dominas, dass Du ein würdiger Sklave bist! Jetzt anrufen und die Peitsche knallen hören.'
  },
  teens: {
    number: {
      first: '09005 001 194',
      tele: '09005 001 096'
    },
    title: 'Unerfahrene Teens (18+)',
    advertisement: 'Unerfahrene Teens suchen eine erste geile Erfahrung',
    imageUrl: '/img/telefonsex/overview/AGE/teen.jpg',
    description: 'Junge, unerfahrene und versaute Teens sind deine Wunschvorstellung? Hier erlebst du heißen Telsex mit Teen Girls ab 18. Die willigen 18+ Teens wissen wie sie Dich mit ihrem jugendlichen Charme um den Finger wickeln können. Greife jetzt zum Hörer und lasse Dich von ihrer verspielten Art verführen.'
  },
  twenty: {
    number: {
      first: '09005 071 421',
      tele: '09005 001 097'
    },
    title: 'Sexy zwanziger (20+)',
    advertisement: 'Willige zwanziger wollen jetzt was geiles erleben',
    imageUrl: '/img/telefonsex/overview/AGE/frauen20plus.jpg',
    description: 'Du stehst auf junge Frauen über 20? Dann hol dir diese Girls die in voller Blüte stehen! Sie probieren sich gerne aus und freuen sich auf eine versaute Spritztour mit Dir! Mit ihnen erlebst du heißen Sex am Telefon - garantiert!'
  },
  bigtits: {
    number: {
      first: '09005 822 552',
      tele: '09005 000 671'
    },
    title: 'Riesen Titten',
    advertisement: 'Frauen mit großen Brüsten suchen zärtliche Liebhaber',
    imageUrl: '/img/telefonsex/overview/AGE/grosse_brueste.jpg',
    description: 'Wer auf große Brüste steht, wird hier beim Telsex auf seine Kosten kommen! Die vollbusigen Frauen wollen mit Dir unanständige Sachen anstellen und ihre dicken Titten mit deinem Sperma eincremen. Für Dich lassen die Girls ihre Glocken läuten!'
  },
  seitensprung: {
    number: {
      first: '09005 001 203',
      tele: '09005 000 678'
    },
    title: 'Heisse Seitensprünge',
    advertisement: 'Wir suchen jemand für einen diskreten Seitensprung',
    imageUrl: '/img/telefonsex/overview/AGE/seitensprung.jpg',
    description: 'Suchst Du nach dem Nervenkitzel und einer erotischen Abwechslung? Eine diskrete und unverbindliche Affäre ist nicht weit. Spüre wieder das Kribbeln und die Begierde. Diese Frauen suchen nur einen unverfänglichen Seitensprung. Jetzt mit diesen Damen heißen Sex am Telefon erleben.'
  }
};