export function setCookie(cname, cvalue, exdays) {
  var cat = '';

  switch (cname) {
    case 'selectedRegion':
      cat = 'functional';
  }

  var cookie = getCookie('cookie_consent');
  var cc = {};

  if (cookie !== '') {
    cc = JSON.parse(cookie);
  }

  if (cat !== '') {
    //@ts-ignore
    if (!cc.hasOwnProperty('level') || !cc.level.includes(cat)) {
      return;
    }
  }

  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}