export function indexCalculator(texts, target, calculation) {
  // check if any text in texts is empty
  if (texts.some(function (text) {
    return text === "" || text === undefined;
  })) {
    return false;
  }

  switch (calculation) {
    case "sum":
      var combined = texts.join(" "); // remove all html tags

      var cleaned = combined.replace(/<[^>]*>?/gm, "");
      return cleaned.split(/\w/).length >= target;

    case "each":
      return texts.every(function (text) {
        return text.split(/\w/).length >= target;
      });

    default:
      return false;
  }
}