import * as Action from "../Types/ReducerTypes";
var runningId = 0;
export default function sendTimedMessage(message, dispatch) {
  message.id = runningId;
  runningId++;
  dispatch({
    type: Action.FEEDBACK_ADD_MESSAGE,
    message: message
  });
  setTimeout(function () {
    dispatch({
      type: Action.FEEDBACK_REMOVE_MESSAGE,
      message: message
    });
  }, 5000);
}