var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

import * as HostStates from "../Types/HostStateTypes";
import { calculateAgeForHost } from "./AgeCalculator";

function isFavorite(host1) {
  return layoutSettings.content.favorites.indexOf(host1.id) === -1 ? 0 : 1;
}
/**
 * Sorts host by online states and favoritism
 *
 * Always gives back a new array
 *
 * @param hosts: IHost[]
 * @return IHost[]
 */


export default function sortHosts(hosts) {
  var newHosts = __spreadArray([], hosts, true);

  return newHosts.sort(function (host1, host2) {
    var simplifiedState1 = HostStates.simplifiedState(host1);
    var simplifiedState2 = HostStates.simplifiedState(host2);

    if (simplifiedState1 === simplifiedState2) {
      return host2.score + isFavorite(host2) * 10000 - (host1.score + isFavorite(host1) * 10000);
    }

    return simplifiedState1 - simplifiedState2;
  });
}
export function filterHosts(hosts) {
  return hosts.filter(function (host) {
    var years = calculateAgeForHost(host);

    if (years < layoutSettings.content.filter.age.min || years > layoutSettings.content.filter.age.max) {
      return false;
    }

    if (layoutSettings.content.hosts.livechat === false && HostStates.isVisitxOnline(host) && !HostStates.is0900Online(host)) {
      return false;
    }

    if (layoutSettings.content.hosts.offline === false && HostStates.isOffline(host)) {
      return false;
    }

    if (layoutSettings.content.filter.preferences.length > 0) {
      return layoutSettings.content.filter.preferences.some(function (element) {
        return host.sexualPreferences.includes(element);
      });
    }

    return true;
  });
}