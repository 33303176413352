export var INCOMING = "incoming";
export var CONNECTED = "connected";
export var VIDEOCHAT = "videochat";
export var OFFLINE = "offline";
export var OFFLINE0900 = "0900offline";
export var FREE = "free";
export function simplifiedState(host) {
  switch (host.status) {
    case FREE:
    case CONNECTED:
    case INCOMING:
      return 0;

    case VIDEOCHAT:
      return 1;

    default:
      return 2;
  }
}
export function is0900Online(host) {
  return [FREE, INCOMING, CONNECTED].indexOf(host.status) !== -1;
}
export function isVisitxOnline(host) {
  return [VIDEOCHAT, OFFLINE0900].indexOf(host.status) === -1;
}
export function isOffline(host) {
  return [OFFLINE].indexOf(host.status) !== -1;
}