var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};

export function getImages(host) {
  var preview16 = host.preview16;
  var preview12 = host.preview12;

  if (typeof host.images === 'undefined' || host.images.length === 0) {
    if (age >= 16 && preview16 !== null) {
      return [preview16];
    }

    if (age >= 12 && preview12 !== null) {
      return [preview12];
    }

    return [{
      id: 0,
      hostId: host.id,
      type: 'preview',
      fsk: 12,
      ratio: 1.33,
      urls: {
        40: '/img/female.png',
        60: '/img/female.png',
        140: '/img/female.png',
        160: '/img/female.png',
        320: '/img/female.png',
        640: '/img/female.png',
        1024: '/img/female.png'
      }
    }];
  }

  if (age >= 16 && preview16 !== null) {
    return __spreadArray([preview16], host.images, true);
  } else if (age >= 12 && preview12 !== null) {
    return __spreadArray([preview12], host.images, true);
  } else {
    return host.images;
  }
}