import { useEffect, useState } from 'react';
export default function useWindowSize() {
  var _a = useState(window.innerWidth),
      width = _a[0],
      setWidth = _a[1];

  useEffect(function () {
    var handler = function () {
      return setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handler);
    return function () {
      return window.removeEventListener('resize', handler);
    };
  });
  return width;
}