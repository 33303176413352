import SockJS from "sockjs-client";

var Communicator =
/** @class */
function () {
  function Communicator() {
    this.wsUrl = wsUrl;
    this.connection = null;
    this.isConnected = false;
    this.messageHandlers = {};
    this.connect();
  }

  Communicator.prototype.connect = function () {
    this.connection = new SockJS(this.wsUrl);
    this.connection.onopen = this.handleNewConnection.bind(this);
    this.connection.onmessage = this.handleMessage.bind(this);
    this.connection.onclose = this.handleCloseConnection.bind(this);
  };

  Communicator.prototype.sendMessage = function (type, payload) {
    if (payload === void 0) {
      payload = {};
    }

    if (this.isConnected) {
      payload.type = type;
      this.connection.send(JSON.stringify(payload));
    }
  };

  Communicator.prototype.handleNewConnection = function () {
    this.isConnected = true;
    this.sendMessage("requestEvents");
  };

  Communicator.prototype.handleMessage = function (message) {
    var jsonMessage = JSON.parse(message.data);

    if (jsonMessage.type && this.messageHandlers[jsonMessage.type]) {
      for (var _i = 0, _a = this.messageHandlers[jsonMessage.type]; _i < _a.length; _i++) {
        var handler = _a[_i];
        handler(jsonMessage.data);
      }
    }
  };

  Communicator.prototype.handleCloseConnection = function () {
    var _this = this;

    this.isConnected = false;
    window.setTimeout(function () {
      _this.connect();
    }, 5000);
  };

  Communicator.prototype.registerMessageHandler = function (type, callback) {
    if (typeof this.messageHandlers[type] === "undefined") {
      this.messageHandlers[type] = [];
    }

    this.messageHandlers[type].push(callback);
  };

  Communicator.prototype.unregisterMessageHandler = function (type, callback) {
    if (typeof this.messageHandlers[type] !== "undefined") {
      this.messageHandlers[type] = this.messageHandlers[type].filter(function (item) {
        return item !== callback;
      });
    }
  };

  Communicator.prototype.hasMessageHandlerForType = function (type) {
    return typeof this.messageHandlers[type] !== "undefined" && this.messageHandlers[type].length > 0;
  };

  return Communicator;
}();

export default new Communicator();