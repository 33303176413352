import 'vanilla-cookieconsent/src/cookieconsent.css';
import { useEffect } from "react";
export var CookieConsent = function () {
  useEffect(function () {
    import('vanilla-cookieconsent/src/cookieconsent').then(function () {
      // @ts-ignore
      var cc = initCookieConsent(); // run plugin with your configuration

      cc.run({
        current_lang: 'de',
        auto_language: true,
        autoclear_cookies: true,
        theme_css: '',
        page_scripts: true,
        cookie_expiration: 365 * 2,
        cookie_name: 'cookie_consent',
        gui_options: {
          consent_modal: {
            layout: 'cloud',
            position: 'bottom center',
            transition: 'zoom' // zoom/slide

          },
          settings_modal: {
            layout: 'box',
            transition: 'zoom' // zoom/slide

          }
        },
        languages: {
          de: {
            consent_modal: {
              title: 'Wir nutzen Cookies!',
              description: 'Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website so angenehm wie möglich zu gestalten und um unser Produkt zu verbessern. Weitere Informationen über unsere Datenerfassungspraktiken finden Sie in unserer <a target="_blank" href="/page/datenschutz">Datenschutzrichtlinie</a>.\n' + '<br/><br/>' + 'Sie können entscheiden welche Cookies Sie zulassen oder ablehnen. Klicken Sie auf "Einstellungen", um eine Liste aller Kategorien angezeigt zu bekommen. Durch Klicken auf "Alle Akzeptieren" erklären Sie sich mit der Verwendung der Cookies einverstanden.',
              primary_btn: {
                text: 'Alle akzeptieren',
                role: 'accept_all' // 'accept_selected' or 'accept_all'

              },
              secondary_btn: {
                text: 'Einstellungen',
                role: 'settings' // 'settings' or 'accept_necessary'

              }
            },
            settings_modal: {
              title: 'Cookie Einstellungen',
              save_settings_btn: 'Speichern',
              accept_all_btn: 'Alle akzeptieren',
              reject_all_btn: 'Alle ablehnen',
              close_btn_label: 'Schließen',
              blocks: [{
                title: 'Cookie Nutzug',
                description: 'Unsere Cookies fallen in folgende Kategorien'
              }, {
                title: 'Essentielle Cookies',
                description: 'Diese Cookies sind notwendig für den einwandfreien technischen Betrieb der Webseite.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"

                }
              }, {
                title: 'Funktionale Cookies',
                description: 'Diese Cookies verbessern die Nutzererfahrung auf unserer Website, indem sie sich deine bevorzugten Einstellungen merken, wie z.B. die Länderauswahl.',
                toggle: {
                  value: 'functional',
                  enabled: false,
                  readonly: false
                }
              }, {
                title: 'Analyse und Optimierung',
                description: 'Diese Cookies werden verwendet um die Nutzung unserer Website mit zu analysieren und verschiedene Optimierungen zu testen, so dass sich unser Produkt stets weiter entwickelt.',
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false
                }
              }, {
                title: 'Mehr Informationen',
                description: 'Für weitere Informationen zum Thema Datenschutz lies unsere <a href="/page/datenschutz" target="_blank">Datenschutzerklärung.</a>'
              }]
            }
          }
        }
      });
    });
  }, []);
  return null;
};