import { useCallback, useLayoutEffect, useState } from 'react';
export var useRect = function (ref) {
  var _a = useState(getRect(ref ? ref.current : null)),
      rect = _a[0],
      setRect = _a[1];

  var handleResize = useCallback(function () {
    if (!ref.current) {
      return;
    } // Update client rect


    setRect(getRect(ref.current));
  }, [ref]);
  useLayoutEffect(function () {
    var element = ref.current;

    if (!element) {
      return;
    }

    handleResize();

    if (typeof ResizeObserver === 'function') {
      var resizeObserver_1 = new ResizeObserver(function () {
        return handleResize();
      });
      resizeObserver_1.observe(element);
      return function () {
        if (!resizeObserver_1) {
          return;
        }

        resizeObserver_1.disconnect();
        resizeObserver_1 = null;
      };
    } else {
      // Browser support, remove freely
      window.addEventListener('resize', handleResize);
      return function () {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [ref.current]);
  return rect;
};

function getRect(element) {
  if (!element) {
    return {
      x: 0,
      y: 0,
      toJSON: function () {},
      bottom: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      width: 0
    };
  }

  return element.getBoundingClientRect();
}