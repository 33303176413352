import axios from "axios";
import * as React from "react";
import { forceCheck } from 'react-lazyload';
import Communicator from "../Communicator";
import { useHostValue } from "../Contexts/HostContext";
import { useWebpushValue } from "../Contexts/WebpushContext";
import * as CommunicatorTypes from "../Types/CommunicatorTypes";
import * as HostStatus from "../Types/HostStateTypes";
import * as Action from "../Types/ReducerTypes";
var useEffect = React.useEffect;

function HostLoader() {
  var _a = useHostValue(),
      state = _a[0],
      dispatch = _a[1];

  var myPush = useWebpushValue();
  useEffect(function () {
    function listener(message) {
      var sender = message.sender,
          status = message.status,
          _a = message.ddi,
          ddi = _a === void 0 ? "" : _a;

      if ([HostStatus.FREE, HostStatus.VIDEOCHAT].indexOf(status) !== -1) {
        var knownIds = state.hosts.map(function (host) {
          return host.id;
        });

        if (knownIds.indexOf(parseInt(sender, 10)) === -1) {
          axios.get("/rest/v2/sender/".concat(sender, "/").concat(age)).then(function (result) {
            return result.data.data;
          }).then(function (host) {
            return dispatch({
              type: Action.EXTEND_HOST_LIST,
              hosts: [host]
            });
          });
          return null;
        }
      }

      dispatch({
        type: Action.HOST_CHANGED_STATUS,
        userId: sender,
        status: status,
        ddi: ddi
      });
    }

    function onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        forceCheck();
      }
    }

    if (state.hosts.length < 50) {
      axios.get("/rest/v2/sender?limit=300&age=".concat(age)).then(function (result) {
        return result.data.data;
      }).then(function (hosts) {
        return dispatch({
          type: Action.REPLACE_HOST_LIST,
          hosts: hosts
        });
      });

      if (myPush.isValid()) {
        myPush.getRegisteredHostsId().then(function (registered) {
          return dispatch({
            type: Action.SET_REGISTERED_HOSTS,
            registered: registered
          });
        }).catch(function () {});
      }
    }

    Communicator.registerMessageHandler(CommunicatorTypes.HOST_STATE_CHANGE, listener);
    document.addEventListener('visibillitychange', onVisibilityChange);
    return function () {
      Communicator.unregisterMessageHandler(CommunicatorTypes.HOST_STATE_CHANGE, listener);
      document.removeEventListener('visibillitychange', onVisibilityChange);
    };
  }, [state.hosts]);
  return null;
}

export default HostLoader;